import { SPACES_ENDPOINT } from "../utils/constants";
import { ISocial } from "./socialService";
import { ISkill } from "./skillService";
import { IRole } from "./roleService";
import api from "../utils/axiosInstance";
import axios from "axios";

export interface IUserSettings {
  avatar?: string;
  username?: string;
  description?: string;
  walletPublicKey?: string;
  price?: number;
  responseTime?: number;
  groupPrice?: number;
  prompt?: string;
  skills?: ISkill[];
  socials?: ISocial[];
  roles?: IRole[];
}

// Update user settings
export const updateUserSettings = async (
  settings: IUserSettings,
  avatarFile?: File
): Promise<IUserSettings | null> => {
  const formData = new FormData();
  formData.append("settings", JSON.stringify(settings));

  // Append the avatar file to the form data if provided
  if (avatarFile) {
    formData.append("avatar", avatarFile);
  }

  try {
    const response = await api.patch("/user/settings", formData, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return {
      avatar: response.data.avatar
        ? `${SPACES_ENDPOINT}${response.data.avatar}`
        : undefined,
      username: response.data.username,
      description: response.data.description,
      walletPublicKey: response.data.wallet_public_key,
      price: response.data.price_crypto_primary,
      responseTime: response.data.response_time_primary,
      groupPrice: response.data.group_price,
      prompt: response.data.prompt,
      roles: response.data.roles,
    };
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.data?.error) {
      throw new Error(error.response.data.error);
    } else {
      console.error("Error updating user settings:", error);
      return null;
    }
  }
};

// Fetch user settings
export const fetchUserSettings = async (): Promise<IUserSettings | null> => {
  try {
    const response = await api.get("/user/settings", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });

    return {
      avatar: response.data.avatar
        ? `${SPACES_ENDPOINT}${response.data.avatar}`
        : undefined,
      username: response.data.username,
      description: response.data.description,
      walletPublicKey: response.data.wallet_public_key,
      price: response.data.price_crypto_primary,
      responseTime: response.data.response_time_primary,
      groupPrice: response.data.group_price,
      prompt: response.data.prompt,
      skills: response.data.skills,
      socials: response.data.socials,
      roles: response.data.roles,
    };
  } catch (error) {
    console.error("Error fetching user settings:", error);
    return null;
  }
};
