// Sidebar.tsx
import MessageBox from '../components/message-box/message-box';
import UserDefaultAvatar from '../assets/images/default-user-avatar.jpg';
import SettingsItem from '../components/settings-item/settings-item';
import ProfileSettingsInput from '../components/profile-settings-input/profile-settings-input';
import FormattingTextarea, { EDITOR_CUSTOM_OPTIONS } from '../components/formatting-textarea/formatting-textarea';
import { useEffect, useMemo, useRef, useState } from 'react';
import SkillList from '../components/skill-list/skill-list';
import QuickChangeIcon from '../assets/icons/quick_change_icon.png';
import { EditorState } from 'draft-js';
import { logout } from '../services/authService';
import useUserStore from '../stores/userStore';
import { IUserSettings, fetchUserSettings, updateUserSettings } from '../services/userSettingsService';
import { stateToHTML } from 'draft-js-export-html';
import useUserSettingsStore from '../stores/userSettingsStore';
import { stateFromHTML } from 'draft-js-import-html';
import SelectSkillModal from '../modals/select-skill-modal/select-skill-modal';
import SelectSocialModal from '../modals/select-social-modal/select-social-modal';
import { useLocation, useNavigate } from 'react-router-dom';
import useAppStore from '../stores/appStore';
import queryString from 'query-string';
import SocialList from '../components/social-list/social-list';
import { ISkill } from '../services/skillService';
import RemoveSkillModal from '../modals/remove-skill-modal/remove-skill-modal';
import { ISocial } from '../services/socialService';
import RemoveSocialModal from '../modals/remove-social-modal/remove-social-modal';
import ProfileSettingsDropdown from '../components/profile-settings-dropdown/profile-settings-dropdown';
import { IRole } from '../services/roleService';

const SettingsPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const queryParams = queryString.parse(location.search);

    // Access specific query parameters
    const socialAdded = queryParams.socialAdded;

    // Stores
    const { showAlertModal } = useAppStore();
    const { user, removeUser, setAvatar } = useUserStore();
    const { userSettings, setUserSettings, removeUserSettings } = useUserSettingsStore();

    // States
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [avatarFile, setAvatarFile] = useState<File>();
    const [avatarUrl, setAvatarUrl] = useState<string>(UserDefaultAvatar);
    const [username, setUsername] = useState<string>("");
    const [walletPublicKey, setWalletPublicKey] = useState<string>("");
    const [price, setPrice] = useState<string>("");
    const [groupPrice, setGroupPrice] = useState<string>("");
    const [responseTime, setResponseTime] = useState<string>("");
    const [prompt, setPrompt] = useState<string>("");
    const [role, setRole] = useState<IRole | null>(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [showAddSkillModal, setShowAddSkillModal] = useState(false);
    const [showAddSocialModal, setShowAddSocialModal] = useState(false);
    const [showRemoveSkillModal, setShowRemoveSkillModal] = useState<ISkill | null>(null);
    const [showRemoveSocialModal, setShowRemoveSocialModal] = useState<ISocial | null>(null);

    useEffect(() => {
        if (socialAdded === "twitter") {
            showAlertModal("Social link successfully added!", "Your Twitter social link was successfully added to your profile.")
        }
        else if (socialAdded === "github") {
            showAlertModal("Social link successfully added!", "Your GitHub was successfully linked to your profile.")
        }
    }, [socialAdded]);

    useEffect(() => {
        const updateUserSettings = (settings: IUserSettings) => {
            setAvatar(settings.avatar ?? UserDefaultAvatar);
            setAvatarUrl(settings.avatar ?? UserDefaultAvatar);

            if (!username && settings.username) setUsername(settings.username);
            if (!walletPublicKey && settings.walletPublicKey) setWalletPublicKey(settings.walletPublicKey);
            if (!price && settings.price) setPrice(Number(settings.price).toFixed(2));
            if (!groupPrice && settings.groupPrice) setGroupPrice(Number(settings.groupPrice).toFixed(2));
            if (!responseTime && settings.responseTime) setResponseTime(settings.responseTime.toString());
            if (!editorState.getCurrentContent().hasText() && settings.description) {
                setEditorState(EditorState.createWithContent(stateFromHTML(settings.description)));
            }
            if (!prompt && settings.prompt) setPrompt(settings.prompt);
        };

        const fetchAndSetSettings = async () => {
            try {
                if (user) {
                    const settings = await fetchUserSettings();
                    if (settings) {
                        setUserSettings(settings);
                        updateUserSettings(settings);
                    } else {
                        removeUserSettings();
                    }
                }
            } catch (error) {
                console.error("Error fetching user settings:", error);
            }
        };

        if (!userSettings) {
            fetchAndSetSettings();
        } else {
            updateUserSettings(userSettings);
        }
    }, [userSettings]);

    const handleAvatarChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            try {
                const { scaledFile, scaledUrl } = await scaleDownImage(file, file.type);
                setAvatarFile(scaledFile);
                setAvatarUrl(scaledUrl);
            } catch (error) {
                console.error('Error scaling down image:', error);
            }
        }
    };

    const scaleDownImage = (file: File, imageType: string): Promise<{ scaledFile: File; scaledUrl: string }> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const image = new Image();
                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    const maxSize = 800;
                    let width = image.width;
                    let height = image.height;

                    if (width > height) {
                        if (width > maxSize) {
                            height *= maxSize / width;
                            width = maxSize;
                        }
                    } else {
                        if (height > maxSize) {
                            width *= maxSize / height;
                            height = maxSize;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx?.drawImage(image, 0, 0, width, height);
                    const scaledDownUrl = canvas.toDataURL('image/jpeg');

                    const scaledFile = dataURLToFile(scaledDownUrl, file.name, imageType);

                    resolve({ scaledFile, scaledUrl: scaledDownUrl });
                };
                image.src = event.target?.result as string;
            };
            reader.readAsDataURL(file);
        });
    };

    const dataURLToFile = (dataUrl: string, fileName: string, fileType: string): File => {
        const binaryString = atob(dataUrl.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(binaryString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryString.length; i++) {
            uint8Array[i] = binaryString.charCodeAt(i);
        }

        const file = new File([uint8Array], fileName, { type: fileType });
        return file;
    };

    const handleIconClick = () => {
        fileInputRef.current?.click();
    };

    const onClickLogout = async () => {
        await logout();
        removeUser();
        removeUserSettings();
    }

    const handleUpdateSettings = async () => {
        try {
            setIsUpdating(true);

            const updatedSettings: IUserSettings = {
                username: username,
                walletPublicKey: walletPublicKey,
                description: stateToHTML(editorState.getCurrentContent(), EDITOR_CUSTOM_OPTIONS),
                price: Number(price),
                groupPrice: Number(groupPrice),
                responseTime: Number(responseTime),
                prompt: prompt,
                roles: role ? [role] : []
            };

            const updated = await updateUserSettings(updatedSettings, avatarFile);
            if (updated) {
                if (avatarFile) {
                    const updatedUserSettings = { ...updated, avatar: avatarUrl, skills: userSettings?.skills, socials: userSettings?.socials };
                    setUserSettings(updatedUserSettings);
                } else {
                    setUserSettings({ ...updated, skills: userSettings?.skills, socials: userSettings?.socials });
                }
                showAlertModal("Profile Updated!", "Your profile has been successfully updated.");
            }
        } catch (error) {
            console.log('Error while updating profile settings', error);
            // @ts-ignore
            showAlertModal("Could not update profile", error.message);
        } finally {
            setIsUpdating(false);
        }
    }

    const handleUpdateDescription = async () => {
        try {
            const updatedSettings: IUserSettings = {
                description: stateToHTML(editorState.getCurrentContent(), EDITOR_CUSTOM_OPTIONS),
            };

            const userSettings = await updateUserSettings(updatedSettings);
            if (userSettings) {
                setUserSettings(userSettings);
                showAlertModal("Description Updated!", "Your profile description has been successfully updated.");
            }
        } catch (error) {
            console.log('Error while updating profile settings', error);
            // @ts-ignore
            showAlertModal("Could not update profile", error.message);
        }
    }

    /*const handleDeleteAccount = async () => {
        const isDeleted = await deleteUser();
        if (isDeleted) {
            removeUser();
            removeUserSettings();
        }
    }*/

    const handleOnClickLogin = () => {
        navigate('/login');
    }

    const userRole = useMemo(() => userSettings?.roles?.[0], [userSettings]);

    return (
        <div className="page-container">
            <MessageBox
                title="Profile Settings"
                text="Your profile is publicly visible, showcase your skills and expertise here. Add one or more socials to be verified. Make sure you add a valid Solana address."
            />
            <h3 className="profile-title me-auto mt-4">My Profile</h3>
            <div style={{ position: 'relative', width: '108px', height: '108px' }} className="mt-4">
                <img
                    src={avatarUrl}
                    alt="Image"
                    className="rounded-circle"
                    style={{ width: '100%', height: '100%' }}
                />
                <img
                    className="position-absolute bottom-0 end-0"
                    src={QuickChangeIcon}
                    alt="Plus"
                    style={{ fontSize: '12px', cursor: 'pointer' }}
                    onClick={handleIconClick}
                />
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleAvatarChange}
                    accept="image/*"
                />
            </div>
            <div className="row mt-4">
                <div className="col-md-6">
                    <ProfileSettingsDropdown
                        label="Role"
                        role={userRole}
                        onSelectedRole={(role: IRole | null) => {
                            setRole(role);
                            if (!userRole && role) {
                                showAlertModal("Attention", "Keep in mind that you only can set a user role once after you pressed on Save Profile.");
                            }
                        }} />
                    <ProfileSettingsInput
                        label="Username"
                        description="Other users will see your username."
                        placeholder="PixelNinja"
                        value={username}
                        onChange={setUsername}
                    />
                    <FormattingTextarea
                        editorState={editorState}
                        placeholder="Hello there, I'm ByteMaster, your digital virtuoso in the world of coding and programming. I thrive on bringing fresh perspectives to every coding challenge."
                        label="Description"
                        description="Describe your experience, skills and tell a bit about yourself - so users can get to know you."
                        buttonTitle="Save"
                        onSendClicked={handleUpdateDescription}
                        onEditorStateChange={setEditorState}
                    />
                    {user ? (
                        isUpdating ? (
                            <button className="btn btn-primary mt-3 btn-custom-purple" disabled={isUpdating}>
                                <div className="spinner-border spinner-border-sm" role="status" />
                            </button>
                        ) : (
                            <button className="btn btn-primary mt-3 btn-custom-purple" disabled={isUpdating} onClick={handleUpdateSettings}>
                                Save Profile
                            </button>
                        )
                    ) : (
                        <button className="btn btn-primary mt-5 btn-custom-purple" onClick={handleOnClickLogin}>
                            Sign In
                        </button>
                    )}
                    <SkillList
                        headline="Skills"
                        description="Add what you are good at to your profile."
                        skills={userSettings?.skills ?? []}
                        addTitle="Add Skills"
                        onAddClick={() => {
                            if (user) {
                                setShowAddSkillModal(true);
                            } else {
                                handleOnClickLogin();
                            }
                        }}
                        onClickRemove={setShowRemoveSkillModal}
                    />
                    <SocialList
                        headline="Socials" // "Notify Me Via"
                        description="Connect your socials to receive a Verified badge. This decreases deceptions, and increases user trust."
                        socials={userSettings?.socials ?? []}
                        addTitle="Add Socials"
                        onAddClick={() => {
                            if (user) {
                                setShowAddSocialModal(true);
                            } else {
                                handleOnClickLogin();
                            }
                        }}
                        onClickRemove={setShowRemoveSocialModal}
                    />
                </div>
                <div className="col-md-6">
                    <div className="d-block d-lg-none mt-3" />
                    <ProfileSettingsInput
                        label="Solana address"
                        description="Add a valid Solana public key. Your earnings will be sent to this address."
                        placeholder="Cm27TVT1VbwYZCTMB7fFpPJzrRCLorW9Zte8Y7iL16De"
                        value={walletPublicKey}
                        onChange={setWalletPublicKey}
                    />
                    <div className="row">
                        <div className="col-md-6">
                            <ProfileSettingsInput
                                label="Access Price"
                                description="Determine a price users need to pay to get access to the bot for 24 hours."
                                value={price}
                                suffix="USDC"
                                allowTwoDecimalPlaces
                                isNumeric
                                placeholder="3"
                                onChange={setPrice}
                            />
                        </div>
                        <div className="col-md-6">
                            <ProfileSettingsInput
                                label="Group Chat Price"
                                description="Determine a price users need to pay to access your group chat."
                                value={groupPrice}
                                suffix="USDC"
                                allowTwoDecimalPlaces
                                isNumeric
                                placeholder="3"
                                onChange={setGroupPrice}
                            />
                        </div>
                    </div>
                    <ProfileSettingsInput
                        inputStyle={{ minHeight: 300 }}
                        label="AI Bot Prompt"
                        description="Define how your AI bot should assist users. For example, you can create a bot that helps developers write Solana smart contracts in Rust."
                        placeholder="E.g., 'You are an expert in Solana development. Assist users in writing and debugging smart contracts in Rust, providing clear explanations and best practices.'"
                        value={prompt}
                        onChange={setPrompt}
                        isTextarea
                    />
                </div>
            </div>
            {user && (
                <>
                    <h3 className="profile-title me-auto mt-5">Account Security</h3>
                    <SettingsItem
                        title="Email"
                        subtitle={user?.email ?? "Username"}
                    /*buttonText="Change Email"
                    onClick={() => {
                        // Handle the button click event here
                    }}*/
                    />
                    <SettingsItem
                        title="Log out"
                        buttonText="Log Out"
                        onClick={onClickLogout}
                    />
                    {/*<SettingsItem
                        title="Delete My Account"
                        subtitle="Your account will be permanently deleted"
                        buttonText="Delete"
                        onClick={handleDeleteAccount}
                    />*/}
                </>
            )}
            {showAddSkillModal && (
                <SelectSkillModal onClose={(updated: boolean) => {
                    setShowAddSkillModal(false);
                    if (updated) {
                        showAlertModal("Profile Updated!", "The new skill has been successfully added to your profile.");
                    }
                }} />
            )}
            {showAddSocialModal && (
                <SelectSocialModal onClose={(updated: boolean) => {
                    setShowAddSocialModal(false);
                    if (updated) {
                        showAlertModal("Profile Updated!", "The new social link has been successfully added to your profile.");
                    }
                }} />
            )}
            {showRemoveSkillModal && (
                <RemoveSkillModal
                    skillId={showRemoveSkillModal.skill_id}
                    skillName={showRemoveSkillModal.name}
                    onClose={() => { setShowRemoveSkillModal(null) }}
                />
            )}
            {showRemoveSocialModal && (
                <RemoveSocialModal
                    socialId={showRemoveSocialModal.social_id}
                    socialName={showRemoveSocialModal.name}
                    onClose={() => { setShowRemoveSocialModal(null) }}
                />
            )}
        </div>
    );
};

export default SettingsPage;
